import { config_utils } from '@/services/configs/config_utils'
import request from '@/services/axios_instance'
import { settings } from '@/variables'
import { fetch_services } from '@/services/fetch/fetch_services'
import { mapGetters } from 'vuex'
export default {
    name: 'Subscribers',
    mixins: [config_utils, fetch_services],
    data: () => ({
        paths: [
            { text: 'Admin', disabled: false, router_name: 'admin-dashboard' },
            { text: 'Subscribers', disabled: false, router_name: 'admin-logs' }
        ],
        panel_items: 1000,
        statistics: {
            active_companies: 0,
            inactive_companies: 0,
            active_users: 0,
            inactive_users: 0
        },
        btnloading: false
    }),
    computed: {
        ...mapGetters(['user'])
    },
    created() {
        this.get_companies()
        this.get_statistics()
        this.$event.$on('btnloading_off', () => {
            this.btnloading = false
        })
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },

    methods: {
        get_companies() {
            this.loadItems(`api/companies`)
        },

        get_statistics() {
            request.get(`api/subscribers/statistics`).then(({ data }) => {
                this.statistics = data
            })
        },
        enable_disable_company(company) {
            request.post(`api/companies/${company.id}/status`).then(({ data }) => {
                let index = this.items.findIndex((i) => i.id === company.id)
                if (~index) {
                    this.items.splice(index, 1, data)
                    this.appSnackbar('Company updated!')
                }
            })
        },
        save_allowed_modules(company) {
            this.sendUpdateConfigs(
                `api/configs/allowed_modules`, { company_id: company.id, modules: company.modules },
                null
            )
        }
    }
}